import { every, isArray, isString } from 'lodash'
import deepObjectFlatten from 'components_sites_settings/helpers/deepObjectFlatten'
import { Deletable } from 'shared/util/types.util'
import { BaseUser } from 'interfaces/User'

/**
 * Flattens Rails' models validation errors
 * @param validationErrors
 */
export const parseValidationErrors = (validationErrors: Record<string, unknown>) => {
  const result: Record<string, unknown> = {}
  Object.keys(validationErrors).forEach((key) => {
    const keyValidationErrors = validationErrors[key]
    if (isArray(keyValidationErrors)) {
      if (every(keyValidationErrors, isString)) {
        result[key] = keyValidationErrors.join('\n ')
      } else {
        Object.assign(result, deepObjectFlatten({ [key]: keyValidationErrors[0] }))
      }
    }
  })
  return result
}

/**
 * Prepends the window origin to a path
 * @example pathToUrl("/guided_advices/1") => https://tradelink.services/guided_advices/1
 * @param path
 */
export const pathToUrl = (path: string) => {
  const { origin } = window.location
  let pathString = path
  if (!path.startsWith('/')) {
    pathString = `/${path}`
  }
  return `${origin}${pathString}`
}

/**
 * Get the initials for a fullName
 * @example
 *  const names = [
 *   'Bassem Mawhoob', // -> BM
 *   'Crystals', // -> CR
 *   'Feel so Close - Radio Edit', // -> FE
 *   ' ', // -> empty
 * ]
 * @param fullName
 */
export const getInitials = (fullName: string): string => {
  const [firstName, ...restNames] = fullName.toUpperCase().trim().split(' ')

  if (!restNames.length) {
    return firstName.substring(0, 2)
  }

  const firstNameInitial = firstName[0]
  const lastNameInitial = restNames.pop()?.[0]

  return `${firstNameInitial}${lastNameInitial}`
}

/**
 * Returns an array without any item that is marked for destruction
 * @param items
 */
export function filterDestructible<T extends Deletable>(items: T[]): T[] {
  return items.filter((c) => c.markedForDeletion !== true)
}

export const fullName = (user: BaseUser) => `${user.firstName} ${user.lastName}`.trim()
