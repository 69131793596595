// Declare a flatten function that takes object as parameter and returns the flattened object
export default function deepObjectFlatten(ob: Record<string, any>): Record<string, any> {
  // The object which contains the final result
  const result: Record<string, any> = {}

  // Loop through the object "ob"
  Object.keys(ob).forEach((i) => {
    // We check the type of the i using typeof() function and recursively call the function again
    if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
      const temp = deepObjectFlatten(ob[i])
      Object.keys(temp).forEach((j) => {
        // Store temp in result
        result[`${i}.${j}`] = temp[j]
      })
    } else {
      // Else store ob[i] in result directly
      result[i] = ob[i]
    }
  })
  return result
}
